export const translations = {
    en: {
        searchPlaceholder: "Search works...",
        introTitle: "I am Salim El Kellouti, a dedicated software engineer looking for new opportunities.",
        introText: "I hold a Bachelor's degree in Computer Engineering and a Master's degree in Computer and Mobile Systems from the Faculté des Sciences et Techniques de Tanger. My academic background laid a solid foundation in the principles of computer science, mathematics, and software engineering, which I've applied effectively throughout my career. Having developed my technical expertise through a series of complex projects and internships during my studies, I have a strong grasp of various programming languages and frameworks, including Python, PHP, Flask, Laravel, and Django. While I have substantial experience as a full-stack developer, my passion lies in backend development where I excel at designing and implementing robust and scalable server-side logic. My career goals are centered around deepening my knowledge and expertise in backend architecture to contribute to more efficient and innovative software solutions. I aim to pursue opportunities that challenge me to utilize my skills in creating impactful and enduring technological advancements.",
        experienceTitle: "Experience",
        academicPathTitle: "Academic Path",
        selectedWorksTitle: "Selected Works",
        personaProjects: "Personal Projects",
        selection: "A selection of non-personal projects in which I collaborated as a fullstack developer (Mainly serving in the Backend).",
        personalSelection: "A selection of personal projects that were either part of my academic journey, or my auto-learning process.",
        fullstackWebMobileDeveloper: "Fullstack Web and Mobile Developer",
        fullstackDeveloperFreelance: "Fullstack Developer (Freelance)",
        juniorPythonDeveloperFreelance: "Junior Python Developer (Freelance)",
        githubTitle: "GitHub",
        linkedinTitle: "LinkedIn",
        cvWorks: [
            {
                title: 'Fullstack Web and Mobile Developer',
                company: 'CAMELDEV S.A.R.L',
                period: 'Mars 2024 - Now'
            },
            {
                title: 'Fullstack Developer (Freelance)',
                company: 'Hena Media LTD',
                period: 'June 2022 - Mars 2024'
            },
            {
                id: 3,
                title: 'Junior Python Developer (Freelance)',
                company: 'Chakir Group',
                period: 'June 2021 - June 2022'
            }
        ],
        workDescriptions: {
            seovoto: "Focused on providing cutting-edge SEO solutions for businesses and marketing experts. I played a key role in backend development using Laravel and MySQL to implement a powerful content management system. Leveraged AJAX-driven interfaces with vanilla JavaScript to boost site responsiveness and performance, greatly enhancing user experience and engagement.",
            turbo4g: "Specializes in selling 4G/5G proxies to technology enthusiasts and professionals. I collaborated on backend development with Laravel and MySQL to create a dynamic content delivery system. Employed AJAX-driven interfaces with vanilla JavaScript to improve site responsiveness and performance, enhancing transaction capabilities and user satisfaction.",
            vodyooga: "This website offers a subscription-based service for yoga and fitness lessons, providing users access to a range of video tutorials and articles. As part of the development team, I worked on the backend using Laravel and MySQL, enhancing content management and user interactions. Implemented responsive templating with Laravel Blade and custom JavaScript for dynamic and interactive content delivery, focusing on site performance and user experience.",
            mediaportal: "A subscription-based platform dedicated to open-source media consumption. My contribution involved the implementation of secure user authentication and member management systems using Laravel and MySQL. Developed advanced features such as multi-factor authentication and session management with Blade templating to create an interactive user interface that enhances the overall experience.",
            paraxpro: "A dashboard aimed at managing pharmaceutical distribution to pharmacies and parapharmacies. The platform uses Laravel, MySQL, and Laravel Blade to handle complex transactions and real-time inventory management efficiently. Security measures such as secure logins and role-based access controls are implemented to protect sensitive distribution data."
        },  
        formationDescriptions: {
            master: "For my master's thesis in Mobile and Computer Systems, I developed a real estate application using Django and Flutter. The app not only features property listings updated via web scraping from Moroccan real estate sites but also hosts images and videos on AWS S3 and employs AWS Lambda for running the scraping scripts. It uniquely leverages AI to enhance search functionality, dynamically expanding searches with a real estate-specific synonyms dictionary and converting textual descriptions directly into SQL queries for more accurate results. Additionally, the application integrates Google Maps for proximity-based notifications and uses Gmail SMTP for direct user communication, demonstrating a seamless integration of various technologies to improve user experience.",
            licence: "As part of our final year project, we undertook an internal internship supervised by one of our professors, working on the development of a centralized web application for patient medical records. The aim of this project was to provide a reliable web-based health system, improving services offered to doctors and patients by making the latter's medical history available online and accessible everywhere, allowing doctors to manage cases with less effort. We used several technologies to realize this project, but the most essential were Laravel, to handle registration, authentication, and routing at the Back-End, and Reactjs for the Front-End of our application.",
            deust: "During the DEUST program, I focused on a broad range of scientific subjects including mathematics, computer science, physics, and chemistry, preparing me for more specialized studies in computer engineering."
        }
    },

    fr: {
        searchPlaceholder: "Rechercher des travaux...",
        introTitle: "Je suis Salim El Kellouti, un ingénieur logiciel dédié cherchant de nouvelles opportunités.",
        introText: "Je détiens une Licence en Génie Informatique et un Master en Systèmes Informatiques et Mobiles de la Faculté des Sciences et Techniques de Tanger. Mon parcours académique a solidement ancré les principes de l'informatique, des mathématiques et du génie logiciel, que j'ai appliqués efficacement tout au long de ma carrière. Ayant développé mon expertise technique à travers une série de projets complexes et de stages lors de mes études, je maîtrise divers langages de programmation et frameworks, y compris Python, PHP, Flask, Laravel et Django. Bien que j'aie une expérience substantielle en tant que développeur full-stack, ma passion réside dans le développement backend où je excelle dans la conception et la mise en œuvre de logiques côté serveur robustes et évolutives. Mes objectifs de carrière sont axés sur l'approfondissement de mes connaissances et de mon expertise en architecture backend afin de contribuer à des solutions logicielles plus efficaces et innovantes. Je cherche à saisir des opportunités qui me défient d'utiliser mes compétences pour créer des avancées technologiques impactantes et durables.",
        experienceTitle: "Expérience",
        academicPathTitle: "Parcours Académique",
        selectedWorksTitle: "Travaux Sélectionnés",
        personaProjects: "Projets Personnels",
        selection: "Une sélection de projets non personnels dans lesquels j'ai collaboré en tant que développeur fullstack (principalement dans le développement Backend)."        ,
        personalSelection: "Une sélection de projets personnels qui ont fait partie de mon parcours académique ou de mon processus d'auto-apprentissage.",
        fullstackWebMobileDeveloper: "Développeur Web et Mobile Fullstack",
        fullstackDeveloperFreelance: "Développeur Fullstack (Freelance)",
        juniorPythonDeveloperFreelance: "Développeur Python Junior (Freelance)",
        githubTitle: "GitHub",
        linkedinTitle: "LinkedIn",
        cvWorks: [
            {
                title: 'Développeur Web et Mobile Fullstack',
                company: 'CAMELDEV S.A.R.L',
                period: 'Mars 2024 - Présent'
            },
            {
                title: 'Développeur Fullstack (Freelance)',
                company: 'Hena Media LTD',
                period: 'Juin 2022 - Mars 2024'
            },
            {
                title: 'Développeur Python Junior (Freelance)',
                company: 'Chakir Group',
                period: 'Juin 2021 - Juin 2022'
            }
        ],
        workDescriptions: {
            seovoto: "Axé sur la fourniture de solutions SEO de pointe pour les entreprises et les experts en marketing. J'ai joué un rôle clé dans le développement backend en utilisant Laravel et MySQL pour mettre en place un système de gestion de contenu performant. J'ai exploité des interfaces pilotées par AJAX avec du JavaScript vanilla pour améliorer la réactivité et les performances du site, augmentant ainsi considérablement l'expérience utilisateur et l'engagement.",
            turbo4g: "Spécialisé dans la vente de proxies 4G/5G à des technophiles et professionnels. J'ai collaboré au développement backend avec Laravel et MySQL pour créer un système dynamique de livraison de contenu. J'ai mis en œuvre des interfaces pilotées par AJAX avec JavaScript pur pour améliorer la réactivité et la performance du site, en améliorant les capacités de transaction et la satisfaction des utilisateurs.",
            vodyooga: "Ce site propose un service basé sur abonnement pour des leçons de yoga et de fitness, offrant aux utilisateurs un accès à une gamme de tutoriels vidéo et d'articles. En tant que membre de l'équipe de développement, j'ai travaillé sur le backend en utilisant Laravel et MySQL, en améliorant la gestion du contenu et les interactions des utilisateurs. J'ai mis en œuvre un templating réactif avec Laravel Blade et du JavaScript personnalisé pour une livraison de contenu dynamique et interactive, en me concentrant sur la performance du site et l'expérience utilisateur.",
            mediaportal: "Plateforme basée sur abonnement dédiée à la consommation de médias open-source. Ma contribution a impliqué la mise en œuvre de systèmes d'authentification sécurisée des utilisateurs et de gestion des membres en utilisant Laravel et MySQL. J'ai développé des fonctionnalités avancées telles que l'authentification multifactorielle et la gestion des sessions avec le templating Blade pour créer une interface utilisateur interactive qui améliore l'expérience globale.",
            paraxpro: "Un tableau de bord conçu pour gérer la distribution pharmaceutique aux pharmacies et parapharmacies. La plateforme utilise Laravel, MySQL et Laravel Blade pour gérer efficacement les transactions complexes et la gestion des stocks en temps réel. Des mesures de sécurité telles que des connexions sécurisées et des contrôles d'accès basés sur les rôles sont mises en œuvre pour protéger les données de distribution sensibles."
        },
        formationDescriptions: {
            master: "Pour mon PFE de master en Systèmes Informatiques et Mobiles, j'ai développé une application immobilière utilisant Django et Flutter. L'application propose des annonces immobilières mises à jour par web scraping à partir de sites immobiliers marocains, tout en hébergeant images et vidéos sur AWS S3 et en exécutant les scripts de scraping via AWS Lambda. Elle exploite l'IA pour améliorer la fonctionnalité de recherche, élargissant dynamiquement les recherches avec un dictionnaire de synonymes spécifique au secteur immobilier et convertissant les descriptions textuelles directement en requêtes SQL pour des résultats plus précis. De plus, l'application intègre Google Maps pour les notifications basées sur la proximité et utilise Gmail SMTP pour communiquer directement avec les utilisateurs, démontrant une intégration fluide de diverses technologies pour améliorer l'expérience utilisateur.",
            licence: "Dans le cadre de notre projet de fin d'études, nous avons effectué un stage interne supervisé par l'un de nos professeurs, travaillant sur le développement d'une application web centralisée pour les dossiers médicaux des patients. L'objectif de ce projet était de fournir un système de santé fiable basé sur le web, améliorant les services offerts aux médecins et aux patients en rendant l'historique médical de ces derniers disponible en ligne et accessible partout, permettant ainsi aux médecins de gérer les cas avec moins d'effort. Nous avons utilisé plusieurs technologies pour réaliser ce projet, mais les plus essentielles étaient Laravel pour gérer l'enregistrement, l'authentification et le routage côté backend, et Reactjs pour le frontend de notre application.",
            deust: "Durant le programme DEUST, je me suis concentré sur un large éventail de matières scientifiques incluant les mathématiques, l'informatique, la physique et la chimie, me préparant à des études plus spécialisées en génie informatique."
        }
    }
}