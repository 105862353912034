import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import gsap from 'gsap';
import { translations } from './translations';


function Main() {
    const [searchOpen, setSearchOpen] = useState(false);
    const [filter, setFilter] = useState('');
    const [language, setLanguage] = useState('en');
    const currentLangData = translations[language];
    const [forceUpdate, setForceUpdate] = useState(false); // State used to force re-render


    const cvWorks = useRef(currentLangData.cvWorks);    
    const works = useRef([
        {
            id: 1,
            link: 'https://turbo4g.com/',
            name: 'Turbo4G',
            imageUrl: 'https://icons.duckduckgo.com/ip2/www.turbo4g.com.ico',
            description: currentLangData.workDescriptions.turbo4g,
        },
        {
            id: 2,
            link: 'https://vodyooga.com/',
            name: 'Vodyooga',
            imageUrl: 'https://vodyooga.com/img/128.png',
            description: currentLangData.workDescriptions.vodyooga,
        },
        {
            id: 3,
            link: 'https://members.vodyooga.com/',
            name: 'Media Portal',
            imageUrl: 'https://icons.duckduckgo.com/ip2/members.vodyooga.com.ico',
            description: currentLangData.workDescriptions.mediaportal,
        },
        {
            id: 4,
            link: 'https://seo.voto/',
            name: 'SEOVOTO',
            imageUrl: 'https://seo.voto/seovoto/assests/images/fav.png',
            description: currentLangData.workDescriptions.seovoto,
        },
        {
            id: 5,
            link: 'https://paraxpro.ma/',
            name: 'ParaxPro',
            imageUrl: 'https://paraxpro.ma/assets/img/pharrrlg.png',
            description: currentLangData.workDescriptions.paraxpro,
        }
    ]);

    const experiments = useRef([
        {
            id: 1,
            link: 'https://github.com/Selk-bit/MarketPlace---SIM---El-Kellouti-Salim',
            name: 'Marketplace App',
            imageUrl: 'https://cdn-icons-png.freepik.com/512/6892/6892801.png',
        },
        {
            id: 2,
            link: 'https://github.com/Selk-bit/MedicalFoldersPFE',
            name: 'Centralized Medical Folders',
            imageUrl: 'https://cdn-icons-png.freepik.com/512/1453/1453631.png',
        },
        {
            id: 3,
            link: 'https://github.com/Selk-bit/TranslationApi',
            name: 'Translation Flask API',
            imageUrl: 'https://static-00.iconduck.com/assets.00/translate-icon-2048x2048-1uvsusdw.png',
        },
        {
            id: 4,
            link: 'https://github.com/Selk-bit/Convertisseur-de-devise',
            name: 'Currency Converter',
            imageUrl: 'https://static-00.iconduck.com/assets.00/currency-exchange-icon-2048x2048-s07u6cmi.png',
        },
        {
            id: 5,
            link: 'https://github.com/Selk-bit/Covid19-Contact-Tracer',
            name: 'COVID19 Contact Tracer',
            imageUrl: 'https://images.squarespace-cdn.com/content/v1/5e4f5b7ee8b790561bbb65e4/1612935738924-F2CEDUK88RLYPPRUGRBE/COVID-19+icon.png',
        },
        {
            id: 6,
            link: 'https://github.com/Selk-bit/FPLReact',
            name: 'FPL-React',
            imageUrl: 'https://pbs.twimg.com/profile_images/1552917490820214784/mMK8Zq8R_400x400.png',
        },
        {
            id: 7,
            link: 'https://github.com/Selk-bit/Quizz',
            name: 'Selk-Quiz',
            imageUrl: 'https://cdn-icons-png.freepik.com/512/10292/10292284.png',
        },
    ]);

    const handleLanguageChange = (newLang) => {
        setLanguage(newLang);
    };

    const formations = useRef([
        {
            id: 1,
            name: 'Master, Systèmes Informatiques et Mobiles',
            facility: 'Faculté des Sciences et Techniques de Tanger',
            imageUrl: 'https://fstt.ac.ma/Portail2023/wp-content/uploads/2023/03/Untitled-3-300x300.png',
            description: currentLangData.formationDescriptions.master,
            period: 'Sep 2022 - Jul 2024',
        },
        {
            id: 2,
            name: 'Licence, Génie informatique',
            facility: 'Faculté des Sciences et Techniques de Tanger',
            imageUrl: 'https://fstt.ac.ma/Portail2023/wp-content/uploads/2023/03/Untitled-3-300x300.png',
            description: currentLangData.formationDescriptions.licence,
            period: 'Sep 2019 - Jun 2020'
        },
        {
            id: 3,
            name: "Diplôme d'études universitaires scientifiques et techniques (DEUST), MATHÉMATIQUES-INFORMATIQUE-PHYSIQUE-CHIMIE",
            facility: 'Faculté des Sciences et Techniques de Tanger',
            imageUrl: 'https://fstt.ac.ma/Portail2023/wp-content/uploads/2023/03/Untitled-3-300x300.png',
            description: currentLangData.formationDescriptions.deust,
            period: 'Sep 2016 - Jun 2019'
        },
    ]);

    const filteredWorks = works.current.filter(work =>
        work.name.toLowerCase().includes(filter.toLowerCase()) ||
        work.description.toLowerCase().includes(filter.toLowerCase())
    );
    const filteredFormations = formations.current.filter(work =>
        work.name.toLowerCase().includes(filter.toLowerCase()) ||
        work.description.toLowerCase().includes(filter.toLowerCase())
    );
    const filteredCvWorks = cvWorks.current.filter(work =>
        work.title.toLowerCase().includes(filter.toLowerCase()) 
    );
    const filteredExperiments = experiments.current.filter(exp =>
        exp.name.toLowerCase().includes(filter.toLowerCase())
    );
    const svgRef = useRef(null);
    const introRef = useRef(null);
    const cvWorkRef = useRef(null);
    const formationRef = useRef(null);
    const workRef = useRef(null);
    const worksRef = useRef(null);
    const inputRef = useRef(null);
    worksRef.current = [];
    const experimentRef = useRef(null);
    const sectionRef = useRef(null);


    useEffect(() => {

        if (svgRef.current) {
            const paths = svgRef.current.querySelectorAll('.cls-1');
            gsap.fromTo(paths, { strokeDashoffset: 1000 }, { strokeDashoffset: 0, duration: 2 });
        }

        if (introRef.current) {
            gsap.fromTo(introRef.current, { opacity: 0 }, { opacity: 1, duration: 1 });
        }

        if (cvWorkRef.current) {
            gsap.fromTo(cvWorkRef.current, { opacity: 0 }, { opacity: 1, duration: 1, delay: 0.5, stagger: 0.1 });
            cvWorks.current = currentLangData.cvWorks;
        }

        if (workRef.current) {
            gsap.fromTo(workRef.current, { opacity: 0 }, { opacity: 1, duration: 1, delay: 0.6, stagger: 0.1 });
            works.current = works.current.map(work => ({
                ...work,
                description: currentLangData.workDescriptions[work.name.toLowerCase().replace(/\s/g, '')]
            }));
        }

        if (formationRef.current) {
            gsap.fromTo(formationRef.current, { opacity: 0 }, { opacity: 1, duration: 1, delay: 0.7, stagger: 0.1 });
            formations.current = formations.current.map(formation => ({
                ...formation,
                description: currentLangData.formationDescriptions[formation.id === 1 ? 'master' : formation.id === 2 ? 'licence' : 'deust']
            }));
        }

        if (experimentRef.current) {
            gsap.fromTo(experimentRef.current, { opacity: 0 }, { opacity: 1, duration: 1, delay: 0.6, stagger: 0.1 });
        }

        if (worksRef.current) {
            worksRef.current.forEach(work => {
                gsap.fromTo(work, { autoAlpha: 0, y: 30 }, { autoAlpha: 1, y: 0, duration: 1, ease: 'power3.out' });

                work.addEventListener('mouseenter', () => {
                    gsap.to(work, { y: -10, scale: 1.05, duration: 0.3, ease: 'power3.out' });
                });
                work.addEventListener('mouseleave', () => {
                    gsap.to(work, { y: 0, scale: 1, duration: 0.3, ease: 'power3.inOut' });
                });
            });
        }
        setForceUpdate(f => !f); // Toggle to force re-render
    }, [language]);

    const addToRefs = el => {
        if (el && !worksRef.current.includes(el)) {
            worksRef.current.push(el);
        }
    };

    const toggleSearch = (event) => {
        if (!inputRef.current || !inputRef.current.contains(event.target)) {
            setSearchOpen(prev => !prev);
        }
    };
    
    const handleSearchChange = event => {
        setFilter(event.target.value);
        if (cvWorkRef.current) {
            gsap.fromTo(cvWorkRef.current, { opacity: 0 }, { opacity: 1, duration: 0, delay: 0 });
        }

        if (workRef.current) {
            gsap.fromTo(workRef.current, { opacity: 0 }, { opacity: 1, duration: 0, delay: 0 });
        }

        if (formationRef.current) {
            gsap.fromTo(formationRef.current, { opacity: 0 }, { opacity: 1, duration: 1, delay: 0.7, stagger: 0.1 });
        }

        if (experimentRef.current) {
            gsap.fromTo(experimentRef.current, { opacity: 0 }, { opacity: 1, duration: 1, delay: 0.6, stagger: 0.1 });
        }
    };
    
    const highlightText = (text, highlight) => {
        if (!highlight.trim()) {
            return text;
        }
        const regex = new RegExp(`(${highlight})`, 'gi');
        const parts = text.split(regex);
        return parts.map((part, index) => 
            regex.test(part) ? <span key={index} style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>{part}</span> : part
        );
    };

    return (
        <div>
            <div className="language-selector">
                <select value={language} onChange={(e) => handleLanguageChange(e.target.value)}>
                    <option value="en">English</option>
                    <option value="fr">Français</option>
                </select>
            </div>
            <div className="search-icon" onClick={toggleSearch}>
                {searchOpen ? (
                    <input
                        ref={inputRef}
                        type="text"
                        value={filter}
                        onChange={handleSearchChange}
                        placeholder="Search works..."
                        style={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1000 }}
                    />
                ) : (
                    <svg viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                )}
            </div>
            <div>
                <svg
                    ref={svgRef}
                    style={{
                        height: '250px',
                        opacity: '1',
                        width: '100%'
                    }}
                    viewBox="0 0 380 121"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <title>Hello Jam 3</title>
                    <defs>
                        <clipPath id="circleView">
                            <circle cx="332.55" cy="76.78" r="27.78" />  {/* Adjusted the radius to match the larger radius of the ellipse */}
                        </clipPath>
                    </defs>
                    <g id="animate">
                        <line className="cls-1" style={{ strokeDasharray: '121', strokeDashoffset: '0' }} x1="9.37" x2="9.37" y2="121" />
                        <line className="cls-1" style={{ strokeDasharray: '95.13', strokeDashoffset: '0' }} x2="95.13" y1="58.56" y2="58.56" />
                        <line className="cls-1" style={{ strokeDasharray: '120.56', strokeDashoffset: '0' }} x1="85.48" x2="85.48" y2="120.56" />
                        <path className="cls-1" d="M110.81,74.94h76.45s0-35.2-31.63-35.2c0,0-34.26-1.88-34.26,33.88,0,0-.18,39.91,34.26,39.91,27.59,0,31.63-24.47,31.63-24.47" style={{ strokeDasharray: '286.718', strokeDashoffset: '0' }} />
                        <line className="cls-1" style={{ strokeDasharray: '121.21', strokeDashoffset: '0' }} x1="222.34" x2="222.34" y2="121.21" />
                        <line className="cls-1" style={{ strokeDasharray: '121.21', strokeDashoffset: '0' }} x1="262.34" x2="262.34" y2="121.21" />
                        <path className="cls-1" d="M325.09,41H334c19.44.69,33.08,15.37,33.08,35.75,0,20.88-14.31,35.78-34.52,35.78-20.37,0-34.52-14.9-34.52-35.78,0-20.1,13.12-34.67,32.28-35.72" style={{ strokeDasharray: '228.196', strokeDashoffset: '0' }} />
                    </g>
                    <g>
                        <circle className="cls-3" cx="565.18" cy="70.53" r="29.68" transform="translate(-2.85 112.32) rotate(-11.32)" />
                        <ellipse className="cls-3" cx="332.55" cy="76.78" rx="25.26" ry="27.78" />
                        <image 
                            href="./1687307448266.jpg" 
                            x="304.79"
                            y="48.89"
                            height="56"
                            width="55.52"
                            clipPath="url(#circleView)" 
                        />
                        <path className="cls-3" d="M154.41,47.48c-11.62,0-19.87,7.08-22.57,19.7h44.79C175.45,55.73,167.88,47.48,154.41,47.48Z" />
            />
                    </g>
                </svg>
                <div className="intro" ref={introRef} style={{ opacity: 0 }}>
                    <strong>{currentLangData.introTitle}</strong>
                    <br />
                    {currentLangData.introText}
                </div>
            </div>
            {/* Additional sections can have similar animations applied */}
            {filteredCvWorks.length > 0 && (
                <section ref={cvWorkRef} style={{ opacity: 0 }}>
                    <h2>{currentLangData.experienceTitle}</h2>
                    {filteredCvWorks.map((cvWork) => (
                        <div className="cv-work" key={cvWork.id}>
                            <div className="years">{cvWork.period}</div>
                            <h3>
                                {cvWork.title} @{' '}
                                <a href={cvWork.link} target="_blank" rel="noreferrer">
                                    {cvWork.company}
                                </a>
                            </h3>
                        </div>
                    ))}
                </section>
            )}
            {filteredWorks.length > 0 && (
                <section ref={experimentRef} style={{ opacity: '0' }}>
                    <h2>{currentLangData.selectedWorksTitle}</h2>
                    <p>
                        {currentLangData.selection}
                    </p>

                    <div className="works">
                        {filteredWorks.map(work => (
                            <div className="work" key={work.id} ref={addToRefs}>
                                <a href={work.link} target="_blank" rel="noreferrer">
                                    <img style={{width: "80px"}} src={work.imageUrl} alt={work.name} />
                                    <span className="link">{highlightText(work.name, filter)}
                                    <p style={{ fontSize: '50%' }}>{highlightText(work.description, filter)}</p>
                                    </span>
                                    <span className="overlay" />
                                </a>
                            </div>
                        ))}
                    </div>
                </section>
            )}

            {filteredExperiments.length > 0 && (
                <section ref={workRef} style={{ opacity: '0' }}>
                    <h2>{currentLangData.personaProjects}</h2>
                    <p>
                        {currentLangData.personalSelection}
                    </p>

                    <div className="experiments">
                        {filteredExperiments.map(work => (
                            <div className="experiment" key={work.id} ref={addToRefs}>
                                <a href={work.link} target="_blank">
                                    <img src={work.imageUrl} />
                                    <span className="link" style={{transform: 'translate(0px, 0px)'}}><em>0{work.id}</em> {work.name}</span>
                                    <span className="overlay" style={{transformOrigin: '0px 100%', transform: 'scale(1, 0)'}} />
                                </a>
                            </div>
                        ))}
                    </div>
                </section>
            )}

            {filteredFormations.length > 0 && (
                <section ref={formationRef} style={{ opacity: '0' }}>
                    <h2>{currentLangData.academicPathTitle}</h2>
                    <div className="works">
                        {filteredFormations.map((work) => (
                            <div style={{ display: 'flex' }} className="cv-work" key={work.id}>
                                <img style={{ width: '50px', height: '50px', marginRight: '5px' }} src={work.imageUrl} alt={work.name} />
                                <div style={{ display: 'block' }}>
                                    <div className="years">{work.period}</div>
                                    <h3>
                                        {work.name}, 
                                        <span> {work.facility}</span>
                                    </h3>
                                    <p style={{ fontSize: '90%' }}>{highlightText(work.description, filter)}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            )}

            <div className="social-share-buttons">
                <a href="https://github.com/Selk-bit" target="_blank" rel="noopener noreferrer" title="GitHub">
                    <img style={{ width: '30px', height: '30px' }} src="https://github.com/fluidicon.png" alt="GitHub" />
                </a>
                <a href="https://www.linkedin.com/in/salim-elkellouti/" target="_blank" rel="noopener noreferrer" title="LinkedIn">
                    <img style={{ width: '30px', height: '30px' }} src="https://static.licdn.com/aero-v1/sc/h/2if24wp7oqlodqdlgei1n1520" alt="LinkedIn" />
                </a>
            </div>
        </div>
    );
}

export default Main;
